import { Ref, ref } from 'vue';
import { getSearch, ListHeaderItem, RequestData } from '@/components/common/list';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import HttpRequest from '@/util/axios.config';
import residentAuthApi from '@/api/pm/resident-auth';

const headers: Array<ListHeaderItem> = [
    {
        label: WordList.DeviceDetailDetailUnitName,
        name: 'UnitName'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }, {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.DeliveryRfCard,
        name: 'Code'
    }, {
        label: WordList.ProperAllTextRepeats,
        name: 'Repeats'
    }, {
        label: WordList.TabelUpdateBoxDevice,
        name: 'DevicesText'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

const initRfCard = () => {
    // list请求借口及请求参数
    const requestData: Ref<RequestData> = ref({
        url: 'v3/web/community/key/getUserRfCardListForOld',
        param: getSearch({
            Build: 'all',
            Room: 'all',
            Key: ''
        })
    });

    // list搜索查询处理
    const updateList = ref(0);
    const saveParamInPath = ref(false);
    const searchList = () => {
        updateList.value += 1;
        saveParamInPath.value = true;
    };

    const add = () => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResidentAuth}?type=rfCard&opera=add`);
    };

    const edit = (val: object) => {
        localStorage.setItem('modifyKey', '1');
        localStorage.setItem('propertyKeyDetail', JSON.stringify(val));
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResidentAuth}?type=rfCard&opera=edit`);
    };

    const download = () => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = 'RFCard_Template.xlsx';
        a.href = '/download/template/RFCard_Template.xlsx';
        a.click();
    };

    const totalCount = ref(0);
    const isShowDeleteDialog = ref(false);
    const deleteAll = (formData: {Password: string}) => {
        const params: {PassWd: string;Type: string} = {
            PassWd: hex_md5(formData.Password),
            Type: 'staffOrResidentDeleteAll'
        };
        residentAuthApi.deleteAllRfCard(params, () => {
            isShowDeleteDialog.value = false;
            updateList.value += 1;
        });
    };

    // 上传
    const file: Ref<any> = ref();
    const form: Ref<any> = ref();
    const setFile = () => {
        file.value.click();
    };
    const importRfCard = () => {
        const formData = new FormData(form.value);
        residentAuthApi.importRfCard(formData, () => {
            updateList.value += 1;
        });
        file.value.value = '';
    };

    return {
        requestData,
        updateList,
        saveParamInPath,
        searchList,
        headers,
        add,
        edit,
        importRfCard,
        deleteAll,
        download,
        totalCount,
        file,
        form,
        setFile,
        isShowDeleteDialog
    };
};
export default initRfCard;
