
import { defineComponent, watch } from 'vue';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import {
    ListActionItem,
    listPagination
} from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import initRfCard from '@/views/pm/old-community/resident-auth/module/init-rf-card';
import CusButton from '@/components/common/customize-button/index.vue';
import DeleteDialog from '@/components/view/pm/delete-all-dialog';
import remove from '@/methods/remove-func';
import { communityForPmApi } from '@/api';

export default defineComponent({
    components: {
        CusButton,
        listPagination,
        AddButton,
        DeleteDialog
    },
    setup() {
        const {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            add,
            edit,
            importRfCard,
            deleteAll,
            download,
            totalCount,
            file,
            form,
            setFile,
            isShowDeleteDialog
        } = initRfCard();
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        // 监听社区选择楼栋，动态改变apt
        watch(() => requestData.value.param.Build, (buildId) => {
            requestData.value.param.Room = 'all';
            setRoomOption(String(buildId));
        });

        // 6.5.1 需求：无归属卡处理
        const cardAction: ListActionItem[] = [{
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify,
            showCondition(val: { IsShowAction: number }) {
                return val.IsShowAction === 1;
            }
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    communityForPmApi.delprrfcard({
                        ID: data.ID
                    }, () => {
                        updateList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        return {
            buildOptions,
            aptOptions,
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            add,
            edit,
            importRfCard,
            deleteAll,
            download,
            totalCount,
            file,
            form,
            setFile,
            isShowDeleteDialog,
            cardAction
        };
    }
});
